import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { ExcelService } from '../services/excel.service';
import { PartstmaintenanceService } from '../services/orderforecast/parts-maintenance/partsmaintenance.service';
import * as mm from 'moment-timezone';
import { forkJoin, Subscription } from 'rxjs';
import { checkAccessPipe, dateFilterComparator, resetSortingAndFilters } from '../constants';

import { OSPStateService } from '../services/osp-state.service';
import { GridApi } from 'ag-grid-community';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { warningMessage, successMessage, freezingMesssage } from '../constants';
import { logData, logErrors } from '../shared/logger';
import { AgGridAngular } from 'ag-grid-angular';
import { PipeLookupService } from '../services/pipe-lookup/pipe-lookup.service';
import { GridTapVolEditorComponent } from './grid-tapVol-component';
import { ReviewChangesComponent } from './review-changes/review-changes.component';
import { InquiryCommentsEditorComponent } from '../partsinquire/inquirycomments-component';
import { TapmoduleComponent } from './dialog-tapmodule/tapmodule.component';
import { TapconfirmComponent } from './tapconfirmation-dialog/tapconfirm.component';





@Component({
  selector: 'app-part-maintenance',
  templateUrl: './part-maintenance.component.html',
  styleUrls: ['./part-maintenance.component.scss'],
})
export class PartMaintenanceComponent implements OnInit, OnDestroy {
  showDownloadUploadButtons: boolean = true;

  namcValue = '01';
  user = '';
  userRole = '';
  rowGroupingDisplayType = 'groupRows';
  groupDefaultExpanded = 1;
  rowData: any = [];
  @ViewChild('faMainGrid') faMainGrid: AgGridAngular;
  columnDefs;

  public defaultColDef;
  rowSelection;

  groupDisplayType = 'groupRows';
  autoGroupColumnDef;
  getDataPath;

  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  editEnabled: boolean = false;
  freezeStatus: boolean = false;
  showToggleAll: boolean = false;
  showClear: boolean = true;
  @ViewChild('osPartsGrid') osPartsGrid;

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedModule: any = [];
  selectedrenban = [];

  selectedContainer = [];

  successMessage = '';
  warningMessage = '';
  vanningFrom: any;
  vanningTo: any;

  kanbanListSelected = [];
  // Dropdown Options
  moduleList: any = [];
  partNoList: any = [];
  renbanList: any = [];
  kanbanList: any = [];
  rowCount: number = 0;
  isEditMode: boolean = false;
  dataCount: number = 0;
  offset: number = 0;

  kanbanSearchValue: any;
  renbanSearchValue: any;
  renbanValue: any;
  renbanRangeSearchValue: any;
  renbanFromSearchValue: any;
  renbanFrom: any
  renbanTo: any;
  process: string;



  showHideList: any = [

    { value: 'container_status', label: 'Renban Status' },
    { value: 'part_status', label: 'Part Status' },
    { value: 'container_renban', label: 'Renban' },
    { value: 'module_number', label: 'Module' },
    { value: 'order_code', label: 'Order Code' },
    { value: 'part_number', label: 'Part No' },
    { value: 'kanban', label: 'Kanban' },
    { value: 'mod_qty_received', label: 'PCS Per Module' },
    // { value: 'qpc', label: 'QPC'},
    { value: 'quantity', label: 'PCS Per Renban' },
    // { label: 'Boxes Per Module',value: 'boxes_per_module',},
    // { label: 'Boxes Per Renban',value: 'boxes_per_renban',},
    { value: 'tapQuantity', label: 'Tap QTY' },
    { value: 'tap_datetime', label: 'Tap DT' },
    { value: 'tap_history', label: 'Tap History' },
    { value: 'updated_userid', label: 'User' },
    { value: 'updated_date_time', label: 'Updated DT' },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;
  disablePartNumber: boolean;
  disableKanban: boolean;
  // Dropdowns

  selectedLinePath: any = [];

  // Dropdown Options
  linePathList: any = [];

  page = 0;
  apiDefaultLimit = 200;
  icons: { groupExpanded: string; groupContracted: string };
  commentsList: any = [];

  uploadError: boolean;
  uploadEnabled: boolean;
  namcName: string;
  files: any[];
  fileBuffer: any;

  exportData: any;

  minVanDate: any;
  maxVanDate: any;

  uploading: boolean = false;

  editApiDefaultLimit = 200;
  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  editApiEndPosition: number = this.editApiDefaultLimit;
  batchRunLogID: any;
  headerHeight: number;
  loading: any;

  flalSubscription$: Subscription;
  headerCheckboxChecked: boolean = false;
  allowedFilesize: number;
  justFileName: any;
  validating: boolean;
  itContact: string;
  easternDate: any;
  defaultPageSize: any = 100000;
  flalLicenseKeySubscription$: Subscription;
  modalComponent = 'modal-component';
  maintenanceSubscription$: Subscription;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    private readonly pipeLookupService: PipeLookupService,
    private readonly partstmaintenanceService: PartstmaintenanceService,
    private readonly stateService: OSPStateService,

    public readonly dialog: MatDialog
  ) {
    this.context = {
      componentParent: this,
    };
  }
  suppressGroupRowsSticky: boolean = true;

  fileName: string = '';
  access1;
  dateFormat = 'YYYY-MM-DD hh:mm:ss';
  newDate1 = String(mm().tz('US/Michigan').format(this.dateFormat))


  ngOnDestroy(): void {
    this.maintenanceSubscription$.unsubscribe();
  }

  ngOnInit(): void {


    const access = checkAccessPipe();
    this.access1 = access.access;



    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 44,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },

        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
      },

      {
        headerName: 'Renban Status',
        headerTooltip: 'Renban Status',
        field: 'container_status',
        sortable: true,
        floatingFilter: true,
        tooltipField: 'container_status',
        width: 130,
      },
      {
        headerName: 'Part Status',
        headerTooltip: 'Part Status',
        field: 'part_status',
        sortable: true,
        width: 130,
        floatingFilter: true,
        tooltipField: 'part_status',

      },

      {
        headerName: 'Renban',
        headerTooltip: 'Renban',
        field: 'container_renban',
        width: 100,
        floatingFilter: true,
        tooltipField: 'container_renban',
      },

      {
        headerName: 'Module',
        field: 'module_number',
        headerTooltip: 'Module',
        width: 80,
        floatingFilter: true,
      },
      {
        headerName: 'Order Code',
        field: 'order_code',
        headerTooltip: 'Order Code',
        width: 120,
        floatingFilter: true,
        tooltipField: 'order_code',

      },

      {
        headerName: 'Part No',
        field: 'part_number',
        width: 120,
        floatingFilter: true,
        headerTooltip: 'Part Number',
        tooltipField: 'part_number',
      },
      {
        headerName: 'Kanban',
        field: 'kanban',
        width: 80,
        floatingFilter: true,
        headerTooltip: 'Kanban',
      },
      {
        headerName: 'PCS Per Module',
        field: 'mod_qty_received',
        width: 120,
        floatingFilter: true,
        headerTooltip: 'Pieces Per Module',
      },
      // {
      //   headerName: 'QPC',
      //   field: 'qpc',
      //   width: 90,
      //   floatingFilter: true,
      //   headerTooltip: 'Quantity Per Container',
      // },
      {
        headerName: 'PCS Per Renban',
        field: 'quantity',
        width: 120,
        floatingFilter: true,
        headerTooltip: 'Pieces Per Renban',
        valueGetter: (params) => {
          return params.data.mod_qty_received + '/' + params.data.quantity;
        },
      },
      // {
      //   headerName: 'Boxes Per Module',
      //   headerTooltip: 'Boxes Per Module',
      //   field: 'boxes_per_module',
      //   sortable: true,
      //   width: 120,
      //   floatingFilter: true,

      // },
      // {
      //   headerName: 'Boxes Per Renban',
      //   headerTooltip: 'Boxes Per Renban',
      //   field: 'boxes_per_renban',
      //   sortable: true,
      //   width: 120,
      //   floatingFilter: true,

      // },
      {
        headerName: 'Tap QTY',
        headerTooltip: 'Tap Quantity',
        field: 'tapQuantity',
        cellRendererParams: { column: 'tapped_qty' },
        width: 90,
        floatingFilter: true,
        cellRendererFramework: GridTapVolEditorComponent,
        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return params.data.tapped_qty;
          } else {
            const value = params.data.tapQuantity?.value ?? params.data.tapped_qty;

            return value;
          }
        },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },

        sortable: true,
      },
      {
        headerName: 'Tap DT',
        headerTooltip: 'Tap Date(mm/dd/yyyy)',
        field: 'tap_datetime',
        sortable: true,
        width: 120,
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        comparator: this.dateComparator,


      },
      {
        headerName: 'Tap History',
        field: 'tap_history',
        cellRendererFramework: InquiryCommentsEditorComponent,
        cellRendererParams: { column: 'tap_history' },
        tooltipField: 'tap_history',
        tooltipComponent: 'customTooltip',
        valueGetter: (params) => {

          return params.data.tap_history;
        },

        width: 100,
        floatingFilter: true,
      },

      {
        headerName: 'User',
        headerTooltip: 'User',
        field: 'updated_userid',
        sortable: true,
        width: 180,
        valueGetter: function (params) {
          if (params.data) {
            if (params.data.updated_userid.startsWith("T_")) {
              return params.data.updated_userid.slice(2);
            }
            else {
              return params.data.updated_userid;

            }



          }
        },
        floatingFilter: true,
        tooltipField: 'updated_userid',

      },
      {
        headerName: 'Updated DT',
        field: 'updated_date_time',
        headerTooltip: 'Updated Date (mm/dd/yyyy hh:mm:ss (Eastern))',
        sortable: true,
        width: 180,
        floatingFilter: true,
        tooltipField: 'updated_date_time',
      },
    ];

    this.headerHeight = 45;
    this.setPageSizeToAll();
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };

    this.maintenanceSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });


  }

  gridApi: GridApi;
  onGridReady(params) {
    this.gridApi = params?.api;
  }

  initializeData() {
    this.onResetDropDown();
    this.itContact = localStorage.getItem('itContact');

    this.spinner.show();
    this.rowData = [];

    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    const access = checkAccessPipe();
    this.access1 = access.access;

    this.loadDrop();
  }

  getModifiedRowData(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }
  getModifiedRowData2(): any {
    return this.rowData.filter((row) => row.rowCheckBox);
  }

  loadDrop() {
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      page_name: 'part-maintenance'
    };

    const dataShowHide = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.pipeLookupService.getModuleList(data),
      this.pipeLookupService.getPartNoList(data),
      this.pipeLookupService.getRenbanList(data),
      this.pipeLookupService.getUserShowHideColumns(dataShowHide),
      this.pipeLookupService.getKanbanList(data),
    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        //if kanbanList, add ALL option
        this.moduleList = [];
        this.partNoList = [];
        this.renbanList = [];
        this.kanbanList = [];
        if (res[0].body.data) {
          this.moduleList.push({ module_number: 'ALL' });
        }

        this.moduleList.push(...res[0].body.data);

        //if item id list, add ALL option
        if (res[1].body.data) {
          this.partNoList.push({ part_number: 'ALL' });
        }

        this.partNoList.push(...res[1].body.data);

        if (res[2].body.data) {
          this.renbanList.push({ container_renban: 'ALL' });
        }

        this.renbanList.push(...res[2].body.data);
        if (res[4].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }
        this.kanbanList.push(...res[4].body.data);

        const { parts_maintenance_columns } = res[3].body.showHideColumnsResponse;

        try {
          const hideItems = JSON.parse(parts_maintenance_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );

            // logData(hideItems);

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) { }

        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();
    this.uploading = false;
    this.validating = false;

    if (error?.error?.message?.required_fields) {
      this.warningMessage = `${warningMessage.requiredFieldsMissing}  ${this.itContact}.`;
    } else {
      this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
    }

    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  piedata=[]
  pieerrorCallback = (error) => {
    console.log("-----error in sending data to pie------")
    console.error(error);
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      msg: 'An error occurred while sending data to PIE during the '+ this.process +' process for NAMC '+ this.namcName,
      error: 'error',
      data: this.piedata
    };
    this.pipeLookupService.sendErrormsg(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        console.log(res);
        this.spinner.hide();
      },
    });
    this.spinner.hide();

    this.removeMessage();
  };


  modifiedRowData = [];

  ReviewChanges() {
    this.removeMessage();

    this.modifiedRowData = this.getModifiedRowData();


    this.modifiedRowData = this.modifiedRowData.filter((element) => element.tapped);

    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }

    Promise.all(
      this.modifiedRowData.map(async (element, index) => {
        let p = [];
        element.error = '';

        //if tap qty Updated, tap qty validation

        if (element.tapped_qty !== element.mod_qty_received) {
          p.push('Tap quantity should be equal to quantity received in the module. ');
        }
        if (element.tapped_qty === element.mod_qty_received) {
          element.part_status1 = 'FULLY TAPPED';
          element.tap_flag = 'Y';
          element.process_name = 'Parts Tap User';
          element.source_name = 'PIPE user'
          element.tap_datetime = this.newDate1;
          this.process='TAPPING/UNTAPPING'



        }
        if (element.tapped_qty === 0) {
          p = [];
          element.part_status1 = '';
          element.tap_flag = '';
          element.process_name = 'Parts Untap User',
          element.tap_datetime = '';
          element.source_name = 'PIPE user',
          element.tap_datetime = '',
          this.process='TAPPING/UNTAPPING'


        }
        if (p.length > 0) {
          element.error = p
            .map(function (val, index) {
              return `${(index + 1).toString()}.   ${val}`;
            })
            .join('\n');
        }
      })
    );

    if (this.modifiedRowData.length > 0) {
      this.openReviewDialog(this.modifiedRowData);
    }
  }
  reviewData() {
    this.openReviewDialog(this.modifiedRowData);
  }

  openReviewDialog(editedRecords) {
    var data = {
      modifiedRowData: editedRecords,
    };
    if (data.modifiedRowData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.height = '350px';
      dialogConfig.width = '1000px';

      dialogConfig.data = data;
      const dialogRef = this.dialog.open(ReviewChangesComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        } else if (res === 'save') {
          var updatedData = [];
          var pieupdatedData = [];
          data.modifiedRowData.forEach((e) => {
            if (!e.tap_history) {
              e.tap_history = '';
            }

            var updatedFields = {
              part_number: e.part_number,
              tap_flag: e.tap_flag,
              mod_qty_received: e.mod_qty_received,
              tapped_qty: e.tapped_qty,
              userid: localStorage.getItem('UserName'),
              part_status: e.part_status1,
              kanban: e.kanban,
              container_renban: e.container_renban,
              module_number: e.module_number,
              old_tapped_qty: e.old_tapped_qty,
              tap_history: e.tap_history
            };
            var updatedFields_pie = {
              part_number: e.part_number,
              tap_flag: e.tap_flag,
              mod_qty_received: e.mod_qty_received,
              tapped_qty: e.tapped_qty,
              userid: localStorage.getItem('UserName'),
              part_status: e.part_status,
              kanban: e.kanban,
              container_renban: e.container_renban,
              module_number: e.module_number,
              old_tapped_qty: e.old_tapped_qty,
              tap_history: e.tap_history,
              process_name: e.process_name,
              tap_datetime: e.tap_datetime,
              order_code: e.order_code,
              source_name: e.source_name

            };

            updatedData.push(updatedFields);
            pieupdatedData.push(updatedFields_pie);

          });

          this.spinner.show();
          this.editMaintenace(updatedData, pieupdatedData);

        }
      });

    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;

      window.scroll(0, 0);
    }
  }
  onEditMode() {
    this.removeMessage();
    let data = [];
    data = this.getModifiedRowData2();

    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (rowNode.data.rowCheckBox) {
          } else {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 200);


    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }


  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '204px';
    dialogConfig.width = '475px';
    dialogConfig.data='ospipe'
    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          }
        };

        this.onSearch(0);
        this.editEnabled = false;
        this.isEditMode = false;
        this.headerCheckboxChecked = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    });
  }

  pietokenValue = '';

  pieToken() {
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole
    };
    this.pietokenValue = '';
    this.pipeLookupService.getPieToken(data)
      .subscribe({
        error: this.errorCallback,
        next: (res) => {
          console.log("res token---------", res);
          this.pietokenValue = res.body.auth_token.access_token;
        }
      })
  }

  cancelEdit() {
    this.removeMessage();
    this.openDialog1();


  }
  getUniqueRecords(array, property) {
    const seen = new Set();
    return array.filter(obj => {
      const value = obj[property];
      if (seen.has(value)) {
        return false;
      } else {
        seen.add(value);
        return true;
      }
    });
  }
  updatedRows = [];
  moduleData = [];
  partData = [];
  tapData = [];
  untapData = [];


  pieObject(updatedData) {
    console.log("updatedData", updatedData);
    this.tapData = [];
    this.untapData = [];
    const noOfrenban = [...new Map(updatedData.map(item => [item['container_renban'], item])).values()];


    for (const y of updatedData) {
      // PIE POST API Tap payload
      const tapObj = {
        business_entity: this.namcValue,
        container_renban: y.container_renban,
        process_name: y.process_name,
        source_name: y.source_name,
        modules: []
      };
      for (const z of updatedData.filter(x => y.container_renban === x.container_renban)) {
        // PIE payload
        const modObj = {
          module_number: z.module_number,
          parts: []
        }
        for (const k of updatedData.filter(x => z.container_renban === x.container_renban &&
          z.module_number === x.module_number)) {
          // PIE POST API Tap Payload
          modObj.parts.push({
            part_number: k.part_number,
            order_code: k.order_code,
            tap_flag: k.tap_flag,
            tap_qty: k.tapped_qty,
            tap_datetime: k.tap_datetime
          })

        }

        if (modObj.parts.length > 0) {

          tapObj.modules.push({ ...modObj })
        }
      }
      if (tapObj.modules.length > 0) {
        this.tapData.push({ ...tapObj })

      }
    }




    for (let i = 0; i < this.tapData.length; i++) {
      // Get unique records by module_number for each index
      const uniqueRecordsByName = this.getUniqueRecords(this.tapData[i].modules, 'module_number');

      // Update modules with unique records for each index
      this.tapData[i].modules = uniqueRecordsByName;
    }
    if (noOfrenban.length !== 1) {
      console.log("inside if")
      const arrayUniqueByKey1 = [...new Map(this.tapData.map(item => [item['container_renban'], item])).values()];

      return arrayUniqueByKey1;

    }
    else {
      return [this.tapData[0]]
    }


  }
    editMaintenace(updatedData, pieupdatedData) {
  
      var data1 = {
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        data: updatedData.slice(this.editApiStartPosition, this.editApiEndPosition),
      };
  
      this.spinner.show();
      this.partstmaintenanceService.editMaintenace(data1).subscribe({
        error: this.errorCallback,
        next: (response) => {
          this.updatedRows.push(...response.body.data.updatedRows);
            // Call pie api
          this.sendToPie(pieupdatedData.slice(this.editApiStartPosition, this.editApiEndPosition));
            if (updatedData.length > this.editApiEndPosition) {
            this.timesEditApiCalled++;
            this.editApiStartPosition = this.timesEditApiCalled * this.editApiDefaultLimit;
            this.editApiEndPosition = Math.min(this.editApiStartPosition + this.editApiDefaultLimit, updatedData.length);
            return this.editMaintenace(updatedData, pieupdatedData);
          }
  
          this.handleFinalOperations();
        },
      });
    }
  

    //   this.piedata=[];

    //   const d1 = {
    //     data: this.pieObject(dataSlice)
    //   };
    //   const data = {
    //     business_entity: this.namcValue,
    //     workid: this.user,
    //     user_role: this.userRole
    //   };
    //   this.pipeLookupService.getPieToken(data)
    //     .subscribe({
    //       error: this.errorCallback,
    //       next: (res) => {
    //         console.log("res token---------", res);
    //         const pieToken = res.body.auth_token.access_token;
    //         setTimeout(() => {
    //           this.partstmaintenanceService.sendToPieData(d1, pieToken).subscribe({
    //             error: this.pieerrorCallback,
    //             next: (response) => {
    //               console.log("response______________________pie", response);
    //             },
    //           });
    //         }, 1000);
    //       }
    //     })
  
  
  
    // }

    // Mail code added
    sendToPie(dataSlice) {
      console.log('---------------------------',dataSlice);
      this.piedata=[];
      let dataSlice1=dataSlice
      dataSlice1.forEach(item => {
        let formattedRecord = {
            namc: this.namcValue,
            renban: item.container_renban,
            module: item.module_number,
            part_number: item.part_number,
            tapped_qty: item.tapped_qty,
            tap_flag: item.tap_flag === null? ' ' : item.tap_flag==='' ? ' ':item.tap_flag,
            tap_datetime: item.tap_datetime === null ? '                   ' :item.tap_datetime=== ''?'                   ' :item.tap_datetime,
            user: localStorage.getItem('UserName')
        };
    
        this.piedata.push(formattedRecord);
    });
      const d1 = {
        data: this.pieObject(dataSlice)
      };
      const data = {
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole
      };
      this.pietokenValue = '';
      this.pipeLookupService.getPieToken(data)
        .subscribe({
          error: this.pieerrorCallback,
          next: (res) => {
            console.log("res token---------", res);
            const pieToken = res.body.auth_token.access_token;
              this.partstmaintenanceService.sendToPieData(d1, pieToken).subscribe({
                error: this.pieerrorCallback,
                next: (response) => {
                  this.piedata=[]
                  console.log("response______________________pie", response);
                },
              });
          }
        })
  
  
  
    }
  
    handleFinalOperations() {
      this.spinner.hide();
      this.timesEditApiCalled = 0;
      this.editApiStartPosition = 0;
      this.editApiEndPosition = this.editApiDefaultLimit;
  
      if (this.updatedRows.length > 0) {
        this.successMessage = successMessage.recordTapped;
        this.spinner.hide();
        this.rowData = this.updatedRows;
        this.updatedRows = [];
      } else {
        this.updatedRows = [];
        this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
      }
  
      this.editEnabled = false;
      this.isEditMode = false;
      this.headerCheckboxChecked = false;
  
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
  
      window.scroll(0, 0);
      this.setPageSizeToAll();
      this.successMessage = successMessage.recordUpdated;
    }
  
  

  handleKanbanSearch(event) {
    this.renbanSearchValue = null;
    this.renbanValue = null;
    this.renbanFrom = null;
    this.renbanTo = null

    this.kanbanSearchValue = this.kanbanSearchValue.trim();
    const value = this.kanbanSearchValue;


    const searchedKanban = this.kanbanList.find((kanban) => kanban.kanban?.toLowerCase() === value.toLowerCase());

    if (!searchedKanban || searchedKanban.kanban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidKanbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];
    this.selectedModule = [];
    this.selectedContainer = [];

    this.selectedKanban = [searchedKanban];

    this.onSearch(0);
  }
  handleRenbanSearch(event) {
    this.kanbanSearchValue = null;
    this.renbanSearchValue = this.renbanSearchValue.trim()
    const value = this.renbanSearchValue;

    this.rowData = [];

    const searchedRenban = this.renbanList.find((renban) => renban.container_renban?.toLowerCase() === value.toLowerCase());

    if (!searchedRenban || searchedRenban.container_renban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidRenbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];
    this.selectedModule = [];

    this.selectedContainer = [];
    this.selectedrenban = [searchedRenban];

    this.onSearch(0);
  }


  renbanSearch(event) {
    this.selectedrenban = []

    this.renbanValue = this.renbanValue.trim().toUpperCase()

    this.omit_special_char(event)
    const onlyHyphens = this.containsOnlyHyphens(this.renbanValue)
    if (onlyHyphens) {
      this.warningMessage = 'Invalid Format, Format should be X, X-Y, X-'
      return false
    }



    const lastChar = this.renbanValue.substring(this.renbanValue.length - 1)
    if (lastChar === '-' && this.renbanValue) {


      this.renbanFromSearchValue = this.renbanValue
      this.handleRenbanFromSearch(event)
      return false


    }

    if (this.renbanValue.includes('-')) {
      this.renbanRangeSearchValue = this.renbanValue
      this.handleRenbanRangeSearch(event)
      return false
    }
    if (!this.renbanValue.includes('-')) {
      this.renbanSearchValue = this.renbanValue
      this.handleRenbanSearch(event)
      return false
    }


  }

  omit_special_char(event) {
    var k; k = event.charCode;
    if (k == 45) {
      return true
    }


    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  containsOnlyHyphens(str: string): boolean {
    return /^-+$/.test(str);
  }

  //renban range seach


  handleRenbanFromSearch(event) {

    this.selectedrenban = []
    this.renbanRangeSearchValue = null
    this.renbanSearchValue = null

    this.kanbanSearchValue = null;
    this.renbanFromSearchValue = this.renbanFromSearchValue.trim().toUpperCase();

    const renbanFromSearchValue = this.renbanFromSearchValue;


    const renbanRangeSearchValueSplit = renbanFromSearchValue.split('-')
    this.renbanFrom = renbanRangeSearchValueSplit[0]
    this.renbanTo = this.renbanList[this.renbanList.length - 1].container_renban








    this.rowData = [];

    const searchedFromRenban = this.renbanList.filter(
      (renban) => renban.container_renban?.toLowerCase() === this.renbanFrom.toLowerCase()
    );

    if (!searchedFromRenban || searchedFromRenban.container_renban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidRenbanSearch;
      return;
    }


    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];
    this.selectedModule=[]

    this.selectedContainer = [];
    this.selectedrenban = [];

    
    

    this.onSearch(0);
  }

  handleRenbanRangeSearch(event) {

    this.selectedrenban = []

    this.renbanSearchValue = null

    this.kanbanSearchValue = null;
    this.renbanRangeSearchValue = this.renbanRangeSearchValue.trim().toUpperCase();

    const renbanRangeSearchValue = this.renbanRangeSearchValue;
    if (renbanRangeSearchValue.includes('-')) {
      const renbanRangeSearchValueSplit = renbanRangeSearchValue.split('-')
      this.renbanFrom = renbanRangeSearchValueSplit[0]
      this.renbanTo = renbanRangeSearchValueSplit[1]


    }

    const greaterRenban = this.findGreaterElement(this.renbanFrom, this.renbanTo)
    console.log(greaterRenban, 'greater');

    if (greaterRenban === this.renbanFrom) {
      this.warningMessage = "Please enter valid range."
      return false
    }


    this.rowData = [];

    const searchedRangeRenban = this.checkIfExist([this.renbanFrom, this.renbanTo])








    if (!searchedRangeRenban || searchedRangeRenban.container_renban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidRenbanSearch;
      return;
    }


    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];


    this.selectedContainer = [];
    this.selectedModule=[]
    this.selectedrenban = [];

    this.onSearch(0);
  }

  checkIfExist(inputValues) {
    // Check if any input value exists in the data array (case-insensitive)
    const uppercaseInputValues = inputValues.map(value => value.toUpperCase());

    // Check if all input values exist in the data array (case-insensitive)
    return uppercaseInputValues.every(input =>
      this.renbanList.some(item => item.container_renban.toUpperCase() === input)
    );

  }

  findGreaterElement(str1: string, str2: string): string {
    const alpha1 = str1.match(/[a-zA-Z]+/)[0];
    const alpha2 = str2.match(/[a-zA-Z]+/)[0];

    if (alpha1 !== alpha2) {
      return alpha1 > alpha2 ? str1 : str2;
    } else {
      const num1 = parseInt(str1.match(/\d+/)[0], 10);
      const num2 = parseInt(str2.match(/\d+/)[0], 10);
      return num1 > num2 ? str1 : str2;
    }
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }
  tapModule() {
    this.onResetDropDown();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '600px';
    dialogConfig.width = '750px';
    var data = {
      modifiedRowData: this.getModifiedRowData(),
    };

    dialogConfig.data = data;
    const dialogRef = this.dialog.open(TapmoduleComponent, dialogConfig);

    const addGetResponse = dialogRef.componentInstance.editEventEmitter.subscribe((res) => {


      if (res === 'cancel') {
        // this.osPartsGrid.gridOptions.api.refreshHeader();
      } else {
        console.log("response", res);
        let data = {
          modifiedRowData: res,
        };


        console.log("else", data);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = 'modal1-component';
        dialogConfig.height = '204px';
        dialogConfig.width = '440px';
        dialogConfig.data = data;


        const dialogRef = this.dialog.open(TapconfirmComponent, dialogConfig);


        dialogRef.afterClosed().subscribe((res) => {
          if (res === 'cancel') {
            return;

          }
          else if (res === 'save') {

            var updatedData = [];
            var pieupdatedData = [];

            console.log("inside save", data.modifiedRowData);
            data.modifiedRowData.forEach((e) => {
              if (!e.tap_history) {
                e.tap_history = '';
              }
              var updatedFields = {
                part_number: e.part_number,
                mod_qty_received: e.mod_qty_received,
                tapped_qty: e.mod_qty_received,
                userid: localStorage.getItem('UserName'),
                part_status: 'FULLY TAPPED',
                tap_flag: 'Y',
                kanban: e.kanban,
                container_renban: e.container_renban,
                module_number: e.module_number,
                old_tapped_qty: e.old_tapped_qty,
                tap_history: e.tap_history,

              };
              var updatedFields_pie = {
                part_number: e.part_number,
                mod_qty_received: e.mod_qty_received,
                tapped_qty: e.mod_qty_received,
                userid: localStorage.getItem('UserName'),
                part_status: 'FULLY TAPPED',
                tap_flag: 'Y',
                kanban: e.kanban,
                container_renban: e.container_renban,
                module_number: e.module_number,
                old_tapped_qty: e.old_tapped_qty,
                tap_history: e.tap_history,
                order_code: e.order_code,
                process_name: 'Parts Tap User',
                source_name: 'PIPE user',
                tap_datetime: this.newDate1

              };

              updatedData.push(updatedFields);
              pieupdatedData.push(updatedFields_pie);

            });

            this.spinner.show();
            this.editMaintenace(updatedData, pieupdatedData);
            this.dialog.closeAll()


          }


        });






      }

    });



  }



  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedModule = [];
    this.selectedrenban = [];
    this.selectedContainer = [];
    this.kanbanSearchValue = null;
    this.renbanSearchValue = null;
    this.renbanValue = null;

    this.kanbanSearchValue = null;

    //pie


    this.renbanFrom = null
    this.renbanTo = null


    this.renbanFromSearchValue = null;
    this.renbanRangeSearchValue = null

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.offset = 0;
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }


  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result =
        yearNumber * 10000 +
        monthNumber * 100 +
        dayNumber +
        hoursNumber * 60 +
        minutesNumber * 10;
      return result;
    }

    // To be used inside filter params when using date filter

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  canDeactivate() {
    return this.editEnabled;
  }

  //Download
  exportAsXLSX(): void {
    this.removeMessage();

    this.spinner.show();

    // eastern time api

    const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    let data = [];

    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData2();
    }

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            e.quantity1 = e.mod_qty_received + '/' + e.quantity;
            if (e.tap_history_old) {
              e.tap_history_old = e.tap_history_old.replaceAll('$', ' ');
            }

            const obj = {
              container_status: e.container_status,
              part_status: e.part_status,
              container_renban: e.container_renban,
              module_number: e.module_number,
              order_code: e.order_code,
              part_number: e.part_number,
              kanban: e.kanban,
              mod_qty_received: e.mod_qty_received,
              // qpc:e.qpc,
              quantity: e.quantity1,
              // boxes_per_module:e.boxes_per_module,
              // boxes_per_renban:e.boxes_per_renban,
              tapped_qty: e.tapped_qty,
              tap_date: e.tap_datetime,
              tap_history_old: e.tap_history_old?.replaceAll('#', ''),
              updated_userid: e.updated_userid,
              updated_date_time: e.updated_date_time,
            };
            exportData.push(obj);
          });

          const headers = [
            'Renban Status',
            'Part Status',
            'Renban',
            'Module',
            'Order Code',
            'Part No',
            'Kanban',
            'PCS Per Module',
            // 'QPC',
            'PCS Per Renban',
            // 'Boxes Per Module',
            // 'Boxes Per Renban',
            'Tap Quantity',
            'Tap DT',
            'Tap History',
            'User',
            'Updated DT',
          ];

          const timestamp = easternCurrentDate

            .split('.')[0]
            .replace('T', '_')
            .split('')

            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(
            exportData,
            'Parts Maintenance' + timestamp,

            headers
          );
        }
        this.spinner.hide();
      }, 1000);
    }
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }

  // call show/hide api to show user preference column

  parts_maintenance_columns;
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) {
      return;
    }

    this.parts_maintenance_columns = hideItems;
    if (!hideItems.length) {
      this.parts_maintenance_columns = [{}];
    }
  }

  checkIfAnySelected() {
    if (this.kanbanSearchValue) {
      return true;
    }
    if (this.renbanSearchValue) {
      return true;
    }
    if (this.renbanRangeSearchValue) return true
    if (this.renbanFromSearchValue) return true

    if (
      this.selectedKanban.length > 0 ||
      this.selectedPartNo.length > 0 ||
      this.selectedModule.length > 0 ||
      this.selectedrenban.length > 0
    ) {
      return true;
    }

    return false;
  }

  clearKanbanValue() {
    this.kanbanSearchValue = null;
  }
  clearRenbanValue() {
    this.renbanSearchValue = null;
    this.renbanValue = null;
    this.renbanFrom = null;
    this.renbanTo = null
  }

  onModuleChange(): void {

    if (this.selectedModule.length >= 1) {
      this.kanbanSearchValue = null;
      this.renbanSearchValue = null;
      this.renbanValue = null;
      this.renbanFrom = null;
      this.renbanTo = null
      this.selectedKanban = [];

      if (this.selectedModule[this.selectedModule.length - 1].module_number === 'ALL') {
        this.selectedModule = [];
        this.selectedModule.push(this.moduleList[0]);
      } else {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null
        // this.selectedKanban = [];


        const indexAll = this.selectedModule.findIndex((data) => data.module_number === 'ALL');

        if (indexAll > -1) {
          this.selectedModule.splice(indexAll, 1);
        }
      }
    }
  }
  onRenbanChange(): void {
    if (this.selectedrenban.length >= 1) {
      if (this.selectedrenban[this.selectedrenban.length - 1].container_renban === 'ALL') {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null
        this.selectedKanban = [];
        this.selectedrenban = [];
        this.selectedrenban.push(this.renbanList[0]);
      } else {
        this.renbanSearchValue = null;
        this.kanbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null
        // this.selectedKanban = [];


        const indexAll = this.selectedrenban.findIndex((data) => data.container_renban === 'ALL');

        if (indexAll > -1) {
          this.selectedrenban.splice(indexAll, 1);
        }
      }
    }
  }
  onPartNoChange(event): void {
    if (this.selectedPartNo.length >= 1) {
      if (this.selectedPartNo[this.selectedPartNo.length - 1].part_number === 'ALL') {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null
        this.selectedKanban = [];


        this.selectedPartNo = [];
        this.selectedPartNo.push(this.partNoList[0]);
      } else {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null
        this.selectedKanban = [];


        const indexAll = this.selectedPartNo.findIndex((data) => data.part_number === 'ALL');

        if (indexAll > -1) {
          this.selectedPartNo.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedPartNo.length > 0) {
      this.disableKanban = true;
    } else {
      this.disableKanban = false;
    }

  }

  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL') {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;

        let indexAll = this.selectedKanban.findIndex((data) => data.kanban === 'ALL');

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }


  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  userCheckChanged(checked, rowIndex) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.spinner.show();

    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }
  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }

  onSearch(offset) {
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }

    this.removeMessage();
    this.editEnabled = false;
    this.isEditMode = false;
    if (this.renbanSearchValue && this.kanbanSearchValue) {
      this.kanbanSearchValue = null;
      this.renbanSearchValue = null;
      this.renbanValue = null;
      this.renbanFrom = null
      this.renbanTo = null

      return
    }


    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      this.offset = 0;
      this.spinner.show();
    }
    if (this.renbanSearchValue) {
      this.kanbanSearchValue = null;
      this.renbanSearchValue = this.renbanSearchValue.trim();
      const value = this.renbanSearchValue;

      this.rowData = [];

      const searchedRenban = this.renbanList.find(
        (renban) => renban.container_renban?.toLowerCase() === value.toLowerCase()
      );

      if (!searchedRenban || searchedRenban.container_renban === 'ALL') {
        this.onResetDropDown();
        this.warningMessage = warningMessage.invalidRenbanSearch;
        return;
      }

      this.selectedKanban = [];
      this.selectedPartNo = [];
      this.selectedrenban = [];
      this.selectedContainer = [];
      this.selectedrenban = [searchedRenban];


      // this.onSearch(0);

    }
    if (this.kanbanSearchValue) {
      this.renbanSearchValue = null;
      this.renbanValue = null;
      this.renbanFrom = null
      this.renbanTo = null

      this.kanbanSearchValue = this.kanbanSearchValue.trim();
      const value = this.kanbanSearchValue;

      this.rowData = [];

      const searchedKanban = this.kanbanList.find(
        (kanban) => kanban.kanban?.toLowerCase() === value.toLowerCase()
      );

      if (!searchedKanban || searchedKanban.kanban === 'ALL') {
        this.onResetDropDown();
        this.warningMessage = warningMessage.invalidKanbanSearch;
        return;
      }

      this.selectedKanban = [];
      this.selectedPartNo = [];
      this.selectedrenban = [];

      this.selectedContainer = [];
      this.selectedKanban = [searchedKanban];

      // this.onSearch(0);

    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.rowData = [];
      this.warningMessage = warningMessage.selectToSearch;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }
    let kanban;
    let selectedPartNo;
    let selectedrenban;
    let selectedModule;

    if (this.selectedKanban.length > 0) {
      if (!this.selectedKanban.some((a) => a.kanban === 'ALL')) {
        kanban = [];
        this.selectedKanban.forEach((element) => {
          kanban.push(element.kanban);
        });
      }
    }

    if (this.selectedPartNo.length > 0) {
      if (!this.selectedPartNo.some((a) => a.part_number === 'ALL')) {
        selectedPartNo = [];
        this.selectedPartNo.forEach((element) => {
          selectedPartNo.push(element.part_number);
        });
      }
    }

    if (this.selectedrenban.length > 0) {
      if (!this.selectedrenban.some((a) => a.container_renban === 'ALL')) {
        selectedrenban = [];
        this.selectedrenban.forEach((element) => {
          selectedrenban.push(element.container_renban);
        });
      }
    }

    if (this.selectedModule.length > 0) {
      if (!this.selectedModule.some((a) => a.module_number === 'ALL')) {
        selectedModule = [];
        this.selectedModule.forEach((element) => {
          selectedModule.push(element.module_number);
        });
      }
    }

    const data = {
      offset: offset,
      limit: 8000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      part_number: selectedPartNo,
      container_renban: selectedrenban,
      module_number: selectedModule,
      kanban: kanban,
      renbanFrom: this.renbanFrom,
      renbanTo: this.renbanTo
    };

    this.partstmaintenanceService.getPartmaintenanceData(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.data) {
          this.rowData.push(...res.body.data.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.data.rowCount);
          const records = this.dataCount - (this.offset + 1) * 8000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 8000);
          } else {
            this.spinner.hide();
            if (res.body.data.rowCount === "0") {
              this.warningMessage = "The selected combination of Renban/Module/Part is not available for Maintenance."
            }
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }
  saveShowHide() {
    this.spinner.show();

    const parts_maintenance_columns = this.parts_maintenance_columns;
    const data = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
    };

    if (parts_maintenance_columns) {
      this.pipeLookupService.updateUserShowHideColumns(data, { parts_maintenance_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          console.log(res);
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }
}
