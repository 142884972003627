import { Component, OnInit } from '@angular/core';
import { EnvironmentConfiguration } from '../environment/services/environment.configuration';
import { MsalService } from '../msal';

@Component({
	selector: 'app-chatbot',
	templateUrl: './chatbot.component.html',
	styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
	constructor(private environment: EnvironmentConfiguration, private msalService: MsalService) { }

	chatbotUrl = this.environment.chatbotUrl;
	// currentModule = '';
	currentModule = 'OSP';

	adalName = localStorage.getItem("adalName");
	user = '';
	adalRole = localStorage.getItem("adalRoles");
	
	role = '';
	namc = '';
	accessForecast = true;
	accessIVE = false;
	accessVehicleSpec = false;
	accessLineShare = false;
	accessDailyNqc = false;
	accessPartsMaster = false;
	workid = '';
	useraccess: any;
	showBot = false;
	lexUserId = 'patti_chatbot' + Date.now();
	windowName = 'patti_chatbot';
	email = '';

	ngOnInit(): void {
		this.adalName = localStorage.getItem("adalName");
		this.adalRole = localStorage.getItem("adalRoles")
		
		// this.user = this.adalName ? JSON.parse(localStorage.getItem("adalName")).split(" ")[0] : "";
		this.user = localStorage.getItem('UserName');

		console.log("The user name = user", this.user);

		// this.role = this.adalRole ? JSON.parse(localStorage.getItem("adalRoles")) : '';
		this.role = localStorage.getItem('UserRoles');
		this.workid = localStorage.getItem("workdayId");
		localStorage.setItem("windowName", this.windowName);
	}

	assignAccess() {
		// this.role = JSON.parse(localStorage.getItem("adalRoles"));
		this.role = localStorage.getItem('UserRoles');

		this.workid = localStorage.getItem("workdayId");
		this.adalName = localStorage.getItem("adalName");
		// this.user = this.adalName ? JSON.parse(localStorage.getItem("adalName")).split(" ")[0] : "";
		this.user = localStorage.getItem('UserName');

		this.email = this.msalService.getAllAccounts()[0].username;
		if (localStorage.getItem('namcSelected')) {
			this.namc = localStorage.getItem('namcName');
		}
		if (localStorage.getItem('userAccess')) {
			this.useraccess = JSON.parse(localStorage.getItem('userAccess'))
			this.useraccess.accessItems.forEach(element => {
				Object.defineProperty(this, element.action, {
					value: element.access,
					writable: true,
					enumerable: true,
					configurable: true
				});
			})
		}

		if (this.accessLineShare && (this.namc !== 'TABC' && this.namc !== 'TMMAL' && this.namc !== 'TMMWV')) {
			// console.log("accessLineshare----", this.accessLineShare)
		} else {
			this.accessLineShare = false
		}

		if (this.accessVehicleSpec && (this.namc !== 'TABC' && this.namc !== 'TMMAL' && this.namc !== 'TMMWV')) {
			// console.log("accessVehicleSpec---", this.accessVehicleSpec)
		} else {
			this.accessVehicleSpec = false
		}

		if (this.accessIVE && (this.namc !== 'TABC' && this.namc !== 'TMMAL' && this.namc !== 'TMMWV')) {
			// console.log("accessIVE-----", this.accessIVE)
		} else {
			this.accessIVE = false;
		}

		if (this.accessForecast) {
			// console.log("accessForecast----", this.accessForecast)
		} else {
			this.accessForecast = false
		}
	}

	goToLink() {
		var w = 500;
		var h = 450;
		var left = (screen.width) - (w / 2);
		var top = (screen.height) - (h / 2);

		this.windowName = localStorage.getItem("windowName");

		//assign the access
		this.assignAccess();

		//pass these values to stand alone app
		const workId = localStorage.getItem("workdayId");
		// const companyCode = localStorage.getItem("Company");
		const companyCode = JSON.parse(localStorage.getItem("namcSelected")).company_code;

		const contextName = "OSP";
		console.log("environment");
		console.log(this.environment);
		// const appid = this.environment.client_id;
		const appid = this.environment.CLIENT_ID;


		// const modules = [{
		// 	'FOD_FC': this.accessForecast.toString(), 'viewValue': 'FC', 'title': 'FORECAST',
		// 	'ci': this.environment.napartsci, 'id': 1
		// },
		// {
		// 	'FOD_LS': this.accessLineShare.toString(), 'viewValue': 'LS', 'title': 'LINE SHARE',
		// 	'ci': this.environment.napartsci, 'id' : 2
		// },
		// {
		// 	'FOD_IVE': this.accessIVE.toString(), 'viewValue': 'IVE', 'title': 'IVE',
		// 	'ci': this.environment.iveci, 'id' : 3
		// },
		// {
		// 	'FOD_VehSpec': this.accessVehicleSpec.toString(), 'viewValue': '100 VS', 'title': '100 VEHICLE SPEC',
		// 	'ci': this.environment.iveci, 'id' : 4
		// },
		// {
		// 	'FOD_DN': this.accessDailyNqc.toString(), 'viewValue': 'DN', 'title': 'DAILY NQC',
		// 	'ci': this.environment.iveci, 'id' : 5
		// },
		// {
		// 	'FOD_PM': this.accessPartsMaster.toString(), 'viewValue': 'PCS', 'title': 'PARTS CONTROL SUITE',
		// 	'ci': this.environment.iveci, 'id' : 6
		// },
		// ];

		const modules = [
			{
				'OSP': this.accessForecast.toString(), 'viewValue': 'OSP', 'title': 'OSP',
				'ci': this.environment.napartsci, 'id': 1
			},
		];
	// 	const sub_modules = [{
	// 		'FC_MODULE': this.accessForecast.toString(), 'id': 1, 'viewValue': 'FC_MODULE', 'title': 'FC_MODULE',
	// 		'ci': this.environment.napartsci
	// 	},
	// 	{
	// 		'LS_MODULE': this.accessLineShare.toString(), 'viewValue': 'LS_MODULE', 'title': 'LS_MODULE',
	// 		'ci': this.environment.napartsci, 'id' : 2
	// 	}
	// ]
	const sub_modules = [{
			'OSP': this.accessForecast.toString(), 'id': 1, 'viewValue': 'OSP', 'title': 'OSP',
			'ci': this.environment.napartsci
		},
	]
	
		const windowLoc = window.location.href;
		
		if (windowLoc.includes('/forecast')) {
			if (windowLoc.includes('/forecast/forecastSub')) {
				this.currentModule = 'FC_SUB'
			}
			else{
			this.currentModule = 'FOD_FC';
			}
		} else if (windowLoc.includes('/lineshare')) {
			if (windowLoc.includes('/lineshare/linesharesub')) {
				this.currentModule = 'LineShare_SUB'
			}else{
			this.currentModule = 'FOD_LS';
			}
		}  else if (windowLoc.includes('/ive/')) {
			if (windowLoc.includes('/veh-spec')) {
				this.currentModule = 'FOD_VehSpec';
			} else {
				this.currentModule = 'FOD_IVE';
			}
		}
		else if (windowLoc.includes('/daily_nqc')) {
			this.currentModule = 'FOD_DN'
		}
		else if (windowLoc.includes('/partscontrolsuite')) {
			this.currentModule = 'FOD_PM'
		}
		else {
			this.currentModule = '';
		}
		// console.log('current module', this.currentModule);

		const mod = JSON.stringify(modules);
		const sub_mod = JSON.stringify(sub_modules);

		const rolArray = [{ 'value': this.role, 'context': true }];
		const rolParam = JSON.stringify(rolArray);
		console.log("contextName",contextName);
		console.log("workId",workId);
		console.log("rolParam",rolParam);
		console.log("appid",appid);
		console.log("companyCode",companyCode);
		// console.log("companyCode",companyCode["company_code"]);
		console.log("mod",mod);
		console.log("sub_mod",sub_mod);
		console.log("namc",this.namc);
		console.log("user",this.user);
		console.log("email",this.email);

		console.log("NamcName",localStorage.getItem('namcName'));

		let qryParam = `context=${contextName}&workId=${workId}&role=${rolParam}&appid=${appid}&companyCode=${companyCode}&modules=${mod}&namc=${this.namc}&user=${this.user}&email=${this.email}`;
		// let qryParam = `context=${contextName}&workId=${workId}&role=${rolParam}&appid=${appid}&companyCode=${companyCode}&modules={}&sub_modules={}&namc=${this.namc}&user=${this.user}&email=${this.email}`;

		if (this.currentModule !== '') {
			qryParam = `${qryParam}&currentModule=${this.currentModule}`;
		}
		console.log('qry', qryParam);

		const handle = window.open(this.environment.chatbotUrl + "?" + qryParam, this.windowName,
			'addressbar=0, toolbar=no, titlebar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

		window.onbeforeunload = function () {
			handle.close();
		};

		// sessionStorage.setItem("chatbotUrl", this.environment.chatbotUrl + "?" + qry);
	}
}
