<div>
	<mat-icon id="close" class="mat-icon material-icons btnClose" role="img" aria-hidden="true" (click)="pushChat(false)">
		cancel
	</mat-icon>

	<span id="feedback" *ngIf="!isBotFeedback">Thank you for rating us!
		<br> Please provide your comments on how we can improve better!</span>
	<span id="botfeedback" *ngIf="isBotFeedback">Please provide your feedback!</span>
	<form id="chatfeedback" #chatfeedback="ngForm" (ngSubmit)="pushChat(true)">
		<mat-form-field class="Input Input-blank">
			<input matInput type="text" type="text" id="inputfeedback" name="inpFeedback" rows="1" cols="15" value="" autocomplete="off"
			 [(ngModel)]="feedback">
			<mat-icon matSuffix style="cursor: pointer;" (click)="pushChat(true)">send</mat-icon>
		</mat-form-field>
	</form>
</div>