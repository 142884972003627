import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<FeedbackComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.dialogRef.disableClose = true;
	}
	feedback = '';
	isBotFeedback = false;

	ngOnInit(): void {
		this.isBotFeedback = this.data.isBotFeedback;
	}

	pushChat(isSend) {
		if (isSend && this.feedback === '') {
			return false;
		}
		this.dialogRef.close({ isFeedback: isSend, data: this.feedback });
	}
}
